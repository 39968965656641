import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Navigator } from "./LanguageHelpers/Navigator"
const Collection = ({ elevators, cta }) => {
  return (
    <div className="grid w-full grid-cols-1 gap-5 py-5 md:grid-cols-2 ">
      {elevators.nodes.map(elevator => (
        <Navigator key={elevator.id} recordId={elevator.id}>
          <div className="flex h-full cursor-pointer flex-col items-start justify-between gap-6 rounded-lg border border-gray-200 bg-white p-6 shadow-md transition hover:bg-gray-100 ">
            <div>
              {elevator.cardIcon && elevator.cardIcon.gatsbyImageData ? (
                <GatsbyImage
                  alt=""
                  image={elevator.cardIcon.gatsbyImageData}
                  style={{
                    objectFit: "contain",
                    height: "64px",
                    width: "64px",
                    filter:
                      " brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(2884%) hue-rotate(169deg) brightness(98%) contrast(91%)",
                  }}
                />
              ) : (
                <img
                  alt=""
                  src={elevator.cardIcon.url}
                  className=" h-16 w-16"
                  style={{
                    filter:
                      " brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(2884%) hue-rotate(169deg) brightness(98%) contrast(91%)",
                  }}
                />
              )}
              <h2 className="text-4xl font-bold leading-tight tracking-tight text-primary-600">
                {elevator.title}
              </h2>
            </div>
            <p>{elevator.description}</p>
            {/*  <Navigator
              className=" text-primary-600 rounded-lg py-2.5 px-4 text-lg font-semibold  transition   "
              recordId={elevator.id}
            >
              {cta}
            </Navigator> */}
          </div>
        </Navigator>
      ))}
    </div>
  )
}

export default Collection
