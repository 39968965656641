import React, { useState } from "react"

import { MultiPageFormProvider } from "../components/ContextProviders/MultiPageFormProvider"
import ElevatorForm from "../components/elevatorInquiry/ElevatorForm"
import { graphql } from "gatsby"
import Collection from "../components/Collection"
import Layout from "../components/layout"
import PageStartBanner from "../components/PageStartBanner"

const ElevatorCollection = ({
  pageContext,
  data: { elevators, elevatorsPage, miscString },
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Layout
      pageData={pageContext}
      seoTitle={elevatorsPage.seo?.seoTitle}
      seoDescription={elevatorsPage.seo?.seoDescription}
      seoImage={elevatorsPage.seo?.image?.seoImageUrl}
    >
      <div className="flex w-full flex-col items-center justify-center">
        <PageStartBanner
          title={elevatorsPage.headerTitle}
          tag={elevatorsPage.tag}
          onClick={() => setOpen(!open)}
          cta={elevatorsPage.cta}
        />

        <div className="mt-5 flex max-w-screen-xl flex-col items-center justify-center gap-5 px-4">
          <MultiPageFormProvider>
            <ElevatorForm open={open} setOpen={() => setOpen(false)} />
          </MultiPageFormProvider>
          <Collection
            cta={miscString.nodes[0].learnMore}
            elevators={elevators}
          />
        </div>
      </div>
    </Layout>
  )
}

export default ElevatorCollection

export const query = graphql`
  query ElevatorRootQuery($locale: String!) {
    elevatorsPage: datoCmsElevatorPage(locale: { eq: $locale }) {
      headerTitle
      tag
      cta
      id: originalId
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
    elevators: allDatoCmsElevator(filter: { locale: { eq: $locale } }) {
      nodes {
        id: originalId
        title
        slug
        locale
        description
        cardIcon {
          gatsbyImageData
          url
        }
      }
    }
    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        learnMore
      }
    }
  }
`
